import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";
import "swiper/css";
import "swiper/css/grid";
import { SwiperSlide } from "swiper/react";

import { BaseLink } from "scmp-app/components/common/base-link";
import { ContentItemHomeSecondary } from "scmp-app/components/content/content-item-render/variants/home-secondary";
import {
  ActionBar,
  CoverImage,
  Headline,
  StyledCoverEntityLink,
  StyledHeadlineEntityLink,
} from "scmp-app/components/content/content-item-render/variants/home-secondary/styles";
import { Swiper } from "scmp-app/components/swiper";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  margin-block-start: 24px;
  padding-block: 24px;
  padding-inline: 20px;

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
  }
  ${props => props.theme.breakpoints.up("desktop")} {
    margin-block-start: 0;
  }
`;

export const Title = styled.div`
  margin-block-end: 16px;

  color: #000000;
  font-weight: 400;
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
  text-transform: uppercase;

  opacity: 0.5;
  ${props => props.theme.breakpoints.between("tablet", "desktop")} {
    margin-block-end: 20px;
  }
`;

export const StyledSwiper = styled(Swiper)`
  &.swiper {
    padding-block-end: 32px;

    ${props => props.theme.breakpoints.up("tablet")} {
      margin-block-end: -16px;
      padding-block-end: 0;
    }

    .swiper-pagination {
      inset-block-end: 0;

      .swiper-pagination-bullet {
        margin: 0;

        background-color: #bbbbbb;

        opacity: 0.5;
        &:not(:last-child) {
          margin-inline-end: 8px;
        }
      }

      .swiper-pagination-bullet-active {
        background-color: #000000;

        opacity: 1;
      }
    }
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
  justify-content: center;
  align-items: center;

  block-size: calc((100% - 16px) / 2) !important;
`;

export const StyledContentItemHomeSecondary = styled(ContentItemHomeSecondary)`
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-block-end: 18px;
  border-block-end: 1px solid rgba(0, 0, 0, 0.1);
  ${props => props.theme.breakpoints.between("tablet", "desktop")} {
    border-block-end: 0;
  }

  &:not(:last-child) {
    margin-block-end: 16px;
  }

  ${StyledHeadlineEntityLink} {
    margin-block-end: 0;
  }

  ${StyledCoverEntityLink} {
    order: 1;

    display: block;

    margin-block: 0;
  }

  ${ActionBar} {
    margin-block-end: 18px;
  }

  ${StyledCoverEntityLink} {
    ${CoverImage} {
      inline-size: 100%;
      block-size: 100%;
      aspect-ratio: 276/144;
    }
  }

  ${Headline} {
    gap: 0;

    color: #000000;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
  }
`;

export const MoreLink = styled(BaseLink)`
  align-self: flex-start;

  margin-block-start: 24px;
  padding-block: 7px;
  padding-inline: 8px;

  color: #4585ff;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  border: 1px solid #4585ff;
  border-radius: 2px;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 4px;
  }
  ${props => props.theme.breakpoints.up("desktop")} {
    margin-block-start: 16px;
  }
`;

export const SwiperContainer = styled.div`
  max-inline-size: 100%;
  ${props => props.theme.breakpoints.between("tablet", "desktop")} {
    display: none;
  }
`;

export const TabletItems = styled.div`
  display: none;
  ${props => props.theme.breakpoints.between("tablet", "desktop")} {
    display: flex;
    flex-direction: column;
  }
`;

export const TopItems = styled.div`
  display: flex;
  gap: 20px;

  ${StyledContentItemHomeSecondary} {
    display: flex;
    flex: 1;
    justify-content: space-between;

    margin-block-end: 16px;
    padding-block-end: 16px;
    border-block-end: 1px solid rgba(0, 0, 0, 0.1);

    ${StyledCoverEntityLink} {
      ${CoverImage} {
        aspect-ratio: 325/196;
      }
    }

    ${ActionBar} {
      display: none;
    }
  }
`;

export const RestItems = styled(TopItems)`
  display: flex;
  gap: 20px;
`;
